import ButtonLoader from 'components/Common/ButtonLoader'
import SubmitLoader from 'components/Common/SubmitLoader'
import { updateTrainingParticipants } from 'helpers/backendHelpers/trainingParticipants'
import {
  generateOtpForExam,
  getAllTrainingQueByTE,
} from 'helpers/backendHelpers/trainingQset'
import moment from 'moment'
import queryString from 'query-string'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Countdown from 'react-countdown'
import OtpInput from 'react-otp-input'
import { useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import green from '../../../src/assets/images/green.png'
import purple from '../../../src/assets/images/purple.png'
import '../practice/McqTest.scss'

let currntobj = {}
let listofQuestion = []
let section = []
let currentoutof10 = ''
let flag = 1
let main = []
let anwser_que = 0
let unanwser_que = 0
let mini = 1
let sece = 1
let emptyArray = []
let score = 0

const TrainingExam = (props) => {
  window.history.pushState(null, '', window.location.href)
  window.onpopstate = function () {
    window.history.pushState(null, '', window.location.href)
  }
  const [questions, setQuestion] = useState()
  const [trainingTitle, setTrainingTitle] = useState('')
  const [IDs, setIDs] = useState()
  const [ID, setID] = useState()
  const [msg, setMessage] = useState('')
  // const [timres, setTimers] = useState([]);
  const [modal, setModal] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [count, setcount] = useState(100)
  const [totalQuestion, setTotalQuestion] = useState([])
  const [addAnswer, setAddAnswer] = useState([])
  const [isFailed, setIsFailed] = useState(false)
  const [disable, setDisable] = useState(false)
  const [disabledButton, setDisabledButton] = useState()
  const [active, setActive] = useState('')
  const [timeZone, setTime] = useState()
  const [examStartTime, setExamStartTime] = useState()
  const [totalMarks, setTotalMarks] = useState()
  const [marksPerQuestion, setMarksPerQuestion] = useState(10)
  const [passingMarks, setPassingMarks] = useState()
  const [varificationModal, setvarificationModal] = useState(false)
  const [otp, setOtp] = useState()
  const [otpFromRes, setOtpFromRes] = useState()
  const [random, setRandom] = useState()
  const { tp_id, tps_id, st_id } = useParams()

  const [data, setData] = useState({
    date: Date.now(),
    delay: 180000,
  })

  const [isLoading, setIsLoading] = useState(false)
  const [noRecords, setNoRecords] = useState(false)
  const [durationTotalTime, setDurationTotalTime] = useState()

  const resultSubmit = (i) => {
    setModal(!modal)
    setIDs(i)
  }

  function msToTime(ms) {
    let seconds = (ms / 1000).toFixed(1)
    let minutes = (ms / (1000 * 60)).toFixed(1)
    let hours = (ms / (1000 * 60 * 60)).toFixed(1)
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1)
    if (seconds < 60) return seconds + ' Sec'
    else if (minutes < 60) return minutes + ' Min'
    else if (hours < 24) return hours + ' Hrs'
    else return days + ' Days'
  }

  const submitAnwser = async () => {
    setDisable(true)
    let allQueAns = []
    totalQuestion.slice(0, 40).map((v) => {
      let selectedOption = addAnswer.filter((data) => v._id === data._id)
      allQueAns.push({
        question: v.question,
        answer: v.answer,
        option: v.option,
        selectedOption: selectedOption[0]?.answer
          ? selectedOption[0]?.answer
          : '',
      })
      addAnswer.map((v1) => {
        if (v.answer.trim() === v1.answer.trim()) {
          //
          if (v._id === v1._id) {
            //
            score = score + marksPerQuestion
          }
        }
      })
    })

    let examEndTime = new Date().getTime()
    let totalTime = msToTime(examEndTime - examStartTime)

    if (score < passingMarks) {
      setTimeout(() => {
        setModal(!modal)
        setMessage(
          `You haven't passed yet. Your score is ${score}. You need at least ${passingMarks} score to pass.`,
        )
        setDisable(false)
        setModal2(!modal2)
      }, 1000)
    } else if (score >= passingMarks) {
      let date = moment(new Date(), 'DD-MM-YYYY')
      let examDate = date.format('DD-MM-YYYY')
      // tps_trainingStatus:
      // 1 = default, 2 = document uploaded waiting for approval, 3 = Document Approved Start Exam, 4 = Exam Passed, 5 = Download Certificate
      let body = {
        tps_score: score,
        tps_trainingStatus: 4,
        tps_examDate: examDate,
      }
      let response = await updateTrainingParticipants(body, tps_id)
      if (response.status) {
        setTimeout(async () => {
          setModal(!modal)
          setMessage(`You have passed the exam.Your Score is ${score}`)
          setDisable(false)
          setModal2(!modal2)
          // let res = await createCertificate(tps_id)
          // if (res.status) {
          // } else {
          //   console.log('res')
          // }
        }, 1000)
      } else {
        setTimeout(() => {
          setModal(!modal)
          setMessage('Something went wrong')
          setDisable(false)
          setModal2(!modal2)
        }, 1000)
      }
    }

    // ApiPost("/result/add", body)
    //     .then((res) => {
    //         //
    //         setModal(!modal);
    //         setMessage(res.data.message);
    //         setIsFailed(res.data.data.isFailed);
    //         setDisable(false);
    //         setModal2(!modal2);

    //     })
    //     .catch((err) => {
    //         if (err.status === 410) {
    //         } else {
    //             toast.error(err.message);
    //             setModal(false);
    //         }
    //     });
  }

  const setthecurruntque = async (i, j) => {
    if (j === 'nex') {
      //
      if (i === listofQuestion.length - 1) {
        alert('question is over')
      } else {
        if (i === random) {
          countTime.current.pause()
          setvarificationModal(true)
          let response = await generateOtpForExam(tps_id)
          let { data } = response
          if (response.status) {
            setOtpFromRes(data.otp)
            console.log("OTP", data.otp, typeof(data.otp));
          }
          // ApiPost("/send_otp_during_exam")
          //     .then((res) => { })
          //     .catch((e) => { });
        }
        currntobj = listofQuestion[i + 1]
        currntobj.ind = i + 1
        let ext1 = i + 2
        let outoften = (ext1 / 10).toString()
        //
        //
        let seflagggg = parseInt(outoften.split('.')[0])
        if (outoften.split('.')[1] === undefined) {
          currentoutof10 = 10
          flag = seflagggg
        } else {
          currentoutof10 = outoften.split('.')[1]

          flag = seflagggg + 1
        }

        setcount(count + 1)
      }
      setcount(count + 1)
      // }
    } else {
      //
      if (i === 0) {
        alert('question is over')
      } else {
        //
        if (i === 'undefined' || i === undefined) {
          currntobj = listofQuestion[0]
          currntobj.ind = 0
          currentoutof10 = 1
          setcount(count + 1)
        } else {
          // outoften = outoften.split(".")[1]
          currntobj = listofQuestion[i - 1]
          currntobj.ind = i - 1
          let ext1 = i
          let outoften = (ext1 / 10).toString()
          //
          //
          let seflagggg1 = parseInt(outoften.split('.')[0])
          if (outoften.split('.')[1] === undefined) {
            currentoutof10 = 10
            flag = seflagggg1
          } else {
            currentoutof10 = outoften.split('.')[1]
            flag = seflagggg1 + 1
          }
          setcount(count + 1)
        }
        setcount(count + 1)
      }
    }
  }

  const mainsetqueastion = (i, j, k) => {
    //
    currntobj = listofQuestion[i]
    currntobj.ind = i
    currentoutof10 = j
    flag = k
    setcount(count + 1)
  }

  const otpVarification = () => {
    if (otp) {
      let body2 = {
        phone_otp: otp,
      }

      if (otp == otpFromRes) {
        countTime.current.start()
        setvarificationModal(false)
        toast.success('Otp verified successfully')
      } else {
        toast.error('Invalid Otp')
      }
    } else {
      toast.error('OTP is required')
    }
  }

  const arrayIncludesInObj = (arr, key, valueToCheck) => {
    return arr.some((value) => value[key] === valueToCheck)
  }

  const otpInput = {
    width: '100%',
    padding: '20px 0',
    borderRadius: '5px',
    // border: 'none',
    margin: '0 5px',
  }
  const handleOtp = (e) => {
    setOtp(e)
  }
  const setAnswer = (e, id, index) => {
    setActive(e)
    if (arrayIncludesInObj(addAnswer, '_id', id)) {
      let filterData = addAnswer.filter((v) => {
        return v._id !== id
      })
      let final = [...filterData, { _id: id, answer: e }]
      setAddAnswer(final)
    } else {
      setAddAnswer([
        ...addAnswer,
        {
          _id: id,
          answer: e,
        },
      ])
    }
    const finalList = listofQuestion.map((item) => {
      if (item._id === id) {
        return { ...item, user_answer: e }
      } else {
        return item
      }
    })
    listofQuestion = finalList
    callsidearray(listofQuestion)
    currntobj = listofQuestion[index]
  }

  const setthecurruntqueint = () => {
    //
    currntobj = listofQuestion[0]
    currntobj.ind = 0
    currentoutof10 = 1
    setcount(count + 1)
  }

  const callsidearray = (i) => {
    main = []
    let k = []
    for (let j = 0; j < i.length; j++) {
      if ((j + 1) % 10 === 0) {
        i[j].ind = j
        k.push(i[j])
        main.push(k)
        k = []
      } else {
        i[j].ind = j

        k.push(i[j])
        if (j === i.length - 1) {
          main.push(k)
        }
      }
    }
  }

  const loadQuestions = async (tp_id) => {
    try {
      let rendom = Math.floor(Math.random() * (15 - 2 + 1)) + 2
      // setRandom(rendom)
      const idValue = queryString.parse(window.location.search)
      setID(idValue.id)
      currntobj = {}
      listofQuestion = []
      section = []
      currentoutof10 = ''
      flag = 1
      main = []
      anwser_que = 0
      unanwser_que = 0

      setIsLoading(true)

      // Fetch all training questions by tp_id
      let response = await getAllTrainingQueByTE(tp_id)

      if (response?.status) {
        setIsLoading(false)

        // Set exam details
        setTotalMarks(response.data?.total_marks)
        setMarksPerQuestion(response.data?.marksPerQuestion)
        setPassingMarks(response.data?.passingMarks)
        setTrainingTitle(response.data?.title)
        setTotalQuestion(response.data?.question_data)

        // Limit to only the first 40 questions
        let limitedQuestions = response.data?.question_data.slice(0, 40)

        // Randomly select a question number
        let max = limitedQuestions.length
        setRandom(Math.floor(Math.random() * max) + 1)

        // Set exam timing details
        setData({
          date: Date.now(),
          delay: response.data?.duration * 60000,
        })

        let tempTotalTime = msToTime(response.data?.duration * 60000)
        setDurationTotalTime(tempTotalTime)
        setTime(moment(new Date()).format())
        setExamStartTime(new Date().getTime())

        // Set first question's subject ID
        setQuestion(limitedQuestions[0].subjectId)

        // Calculate the number of sections (10 questions per section)
        let ext = Math.ceil(limitedQuestions.length / 10)
        for (let i = 1; i <= ext; i++) {
          section.push(i)
        }

        // Use the limited set of 40 questions
        listofQuestion = limitedQuestions

        // Call the sectioning function
        callsidearray(limitedQuestions)

        setthecurruntqueint() // Initialize the first question
        setNoRecords(limitedQuestions.length === 0 ? true : false)
      } else {
        setIsLoading(false)
        console.log('Something went wrong!')
      }
    } catch (error) {
      console.log('errormcqtest', error)
      setIsLoading(false)
    }
  }

  function msToTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds

    return hours + ':' + minutes + ':' + seconds
  }
  useEffect(() => {
    loadQuestions(tp_id)
  }, [tp_id])

  const countTime = useRef()

  useEffect(() => {
    setDisabledButton(true)
    // toggleFullScreen();
  }, [])

  const cancelModal = () => {
    setModal(!modal)
    score = 0
  }
  document.onkeydown = function (e) {
    if (e.keyCode === 123) {
      return false
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
      return false
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
      return false
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
      return false
    }
    if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
      return false
    }
  }

  const handleUserKeyPress = (e) => {
    //
    if (e.keyCode === 116) {
      e.preventDefault()
    }
    if (e.keyCode === 122) {
      e.preventDefault()
    }
    if (e.keyCode === 27) {
      e.preventDefault()
    }

    if (e.ctrlKey === true) {
      if (e.keyCode === 82) {
        e.preventDefault()
      }
    }
    if (e.altKey === true) {
      if (e.keyCode === 68) {
        e.preventDefault()
      }
    }
  }

  window.addEventListener('keydown', handleUserKeyPress)

  window.onbeforeunload = function () {
    return true
  }

  return (
    totalQuestion && (
      <div>
        <ToastContainer position="top-right" />
        <div className="container">
          <div className="py-3 bg-white">
            <div className="d-flex flexColumn align-items-center px-3 py-3 justify-content-between box_shadow rounded">
              <h5 className="">{trainingTitle}</h5>
              <div className="d-flex align-items-center justify-content-between px-2 py-2">
                <h6 className="text-theme pe-5">Total Marks: {totalMarks}</h6>
                <h6 className="d-flex pe-5">
                  Time:
                  <div id="countdown2">
                    <Countdown
                      date={data?.date ? data?.date + data?.delay : ''}
                      ref={countTime}
                      controlled={false}
                      renderer={(props) => (
                        //

                        <span>
                          {props.hours.toString().padStart(2, '0')}:
                          {props.minutes.toString().padStart(2, '0')}:
                          {props.seconds.toString().padStart(2, '0')}
                        </span>
                      )}
                      onComplete={() => submitAnwser(questions)}
                    />
                  </div>
                  / {durationTotalTime}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-3 ">
          <div style={{ minHeight: '400px' }}>
            {isLoading ? (
              <div
                style={
                  isLoading
                    ? {
                        position: 'relative',
                        opacity: '0.8',
                        minHeight: '400px',
                      }
                    : {}
                }
              >
                <SubmitLoader />
              </div>
            ) : (
              <>
                {noRecords && (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: '400px' }}
                  >
                    <h1>No system activity found.</h1>
                  </div>
                )}
                <div className="row">
                  <div className="col-md-9 position-relative">
                    {disabledButton === true ? (
                      totalQuestion && totalQuestion.length > 0 ? (
                        <>
                          <div className="pastPaperBox bg-white mb-3">
                            <div className="h6 font_medium mb-2">
                              {currntobj?.question}
                            </div>
                            <div
                              className={`${
                                currntobj?.option && currntobj?.option[0] === ''
                                  ? 'd-none '
                                  : 'd-flex '
                              }${
                                currntobj?.option &&
                                currntobj?.option[0] ===
                                  currntobj?.user_answer &&
                                ' bg_light_blue box_shadow2'
                              }`}
                              onClick={() =>
                                setAnswer(
                                  currntobj?.option[0],
                                  currntobj?._id,
                                  currntobj?.ind,
                                )
                              }
                            >
                              <span className="rounded-circle border">A</span>
                              <span className="ms-2 pt-2">
                                {currntobj?.option && currntobj?.option[0]}
                              </span>
                            </div>
                            <div
                              className={`${
                                currntobj?.option && currntobj?.option[1] === ''
                                  ? 'd-none '
                                  : 'd-flex '
                              } ${
                                currntobj?.option &&
                                currntobj?.option[1] ===
                                  currntobj?.user_answer &&
                                ' bg_light_blue box_shadow2'
                              }`}
                              onClick={() =>
                                setAnswer(
                                  currntobj?.option[1],
                                  currntobj?._id,
                                  currntobj?.ind,
                                )
                              }
                            >
                              <span className="rounded-circle border">B</span>
                              <span className="ms-2 pt-2">
                                {currntobj?.option && currntobj?.option[1]}
                              </span>
                            </div>
                            <div
                              className={`${
                                currntobj?.option && currntobj?.option[2] === ''
                                  ? 'd-none '
                                  : 'd-flex '
                              }${
                                currntobj?.option &&
                                currntobj?.option[2] ===
                                  currntobj?.user_answer &&
                                ' bg_light_blue box_shadow2'
                              }`}
                              onClick={() =>
                                setAnswer(
                                  currntobj?.option[2],
                                  currntobj?._id,
                                  currntobj?.ind,
                                )
                              }
                            >
                              <span className="rounded-circle border">C</span>
                              <span className="ms-2 pt-2">
                                {currntobj?.option && currntobj?.option[2]}
                              </span>
                            </div>
                            <div
                              className={`${
                                currntobj?.option && currntobj?.option[3] === ''
                                  ? 'd-none '
                                  : 'd-flex '
                              }${
                                currntobj?.option &&
                                currntobj?.option[3] ===
                                  currntobj?.user_answer &&
                                ' bg_light_blue box_shadow2'
                              }`}
                              onClick={() =>
                                setAnswer(
                                  currntobj?.option[3],
                                  currntobj?._id,
                                  currntobj?.ind,
                                )
                              }
                            >
                              <span className="rounded-circle border">D</span>
                              <span className="ms-2 pt-2">
                                {currntobj?.option && currntobj?.option[3]}
                              </span>
                            </div>
                            <div
                              className={`${
                                currntobj?.option && currntobj?.option[4] === ''
                                  ? 'd-none '
                                  : 'd-flex '
                              }${
                                currntobj?.option &&
                                currntobj?.option[4] ===
                                  currntobj?.user_answer &&
                                ' bg_light_blue box_shadow2'
                              }`}
                              onClick={() =>
                                setAnswer(
                                  currntobj?.option[4],
                                  currntobj?._id,
                                  currntobj?.ind,
                                )
                              }
                            >
                              <span className="rounded-circle border">E</span>
                              <span className="ms-2 pt-2">
                                {currntobj?.option && currntobj?.option[4]}
                              </span>
                            </div>

                            <div className="pt-4 d-flex justify-content-center mcq-btn">
                              {currntobj.ind === 0 ? (
                                ''
                              ) : (
                                <button
                                  className="cmn--btn w-25 form-control me-5"
                                  // className="rounded border-none py-2 mx-2 viewAllBtn"
                                  onClick={() =>
                                    setthecurruntque(currntobj?.ind, 'pre')
                                  }
                                >
                                  Previous
                                </button>
                              )}
                              {currntobj.ind === listofQuestion.length - 1 ? (
                                ''
                              ) : (
                                <button
                                  className="cmn--btn w-25 form-control"
                                  onClick={() =>
                                    setthecurruntque(currntobj?.ind, 'nex')
                                  }
                                >
                                  Next
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="vh-100 d-flex justify-content-center align-items-center">
                            <h3>No system activity found.</h3>
                          </div>
                        </>
                      )
                    ) : (
                      <div className="box_shadow rounded_1 p-5 mt-5 text-center d-flex align-items-center justify-content-center h-75">
                        {/* <Spin /> */}
                      </div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="mb-3">
                      <button
                        className="submit--btn w-100 form-control me-5"
                        // className="rounded border-none py-3 text-white font_bold linear_gradient w-100"
                        onClick={() => resultSubmit(questions)}
                      >
                        Submit
                      </button>
                    </div>
                    <div className=" box_shadow rounded_1 border p-3 mb-3 bg-white">
                      <h5 className="font_size_22 font_medium color_gray">
                        Questions
                      </h5>
                      <div class="justify-content-between py-2">
                        <div class="d-flex align-items-center">
                          <img src={purple} alt="" />
                          <p class="font_size_14 font_medium color_light_gray px-2">
                            Attempted&nbsp;
                            <span class="font_size_14 font_bold color_gray">
                              {addAnswer?.length}
                            </span>
                          </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                          <img src={green} alt="" />
                          <p class="font_size_14 font_medium color_light_gray px-2">
                            Not Attempted &nbsp;
                            <span class="font_size_14 font_bold color_gray">
                              {totalQuestion.slice(0, 40).length -
                                addAnswer?.length}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="scroll">
                        {main &&
                          main.map((book, i) => {
                            return (
                              <div className="" key={i}>
                                <p className="h6 py-2">Section {i + 1}</p>

                                <div className="d-flex flex-wrap">
                                  {book &&
                                    book.map((book1, j) => {
                                      //
                                      return (
                                        <div
                                          key={j}
                                          className={
                                            !book1?.user_answer
                                              ? 'fixwidth rounded-pill text-center mx-1 my-1 text-white px-2 py-2 bg_blue pointer'
                                              : 'fixwidth rounded-pill text-center mx-1 my-1 text-white px-2 py-2 bg_purple pointer'
                                          }
                                          onClick={() =>
                                            mainsetqueastion(
                                              book1.ind,
                                              j + 1,
                                              i + 1,
                                            )
                                          }
                                        >
                                          {j + 1}
                                        </div>
                                      )
                                    })}
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Modal
          show={modal}
          centered
          onHide={() => setModal(!modal)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Body>
            <h5 className="font_size_20 font_bold color_light_gray">Confirm</h5>
            <h6 className="mt-2">
              Make sure you have attempted all questions and want to finish your
              examination.{' '}
            </h6>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <div className="d-flex justify-content-center">
              <button
                disabled={disable}
                // type="button"
                onClick={() => submitAnwser(IDs)}
                className="cmn--btn viewAllBtn rounded py-2 mx-2"
              >
                <div className="d-flex align-items-center justify-content-center">
                  {disable && <ButtonLoader></ButtonLoader>}

                  <span>Submit</span>
                </div>
                {/* {disable ? <Spin size="small" /> : 'Submit'} */}
              </button>
              <button
                // type="button"
                onClick={() => cancelModal()}
                className="cancel--btn viewAllBtn rounded py-2"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={modal3}
          centered
          onHide={() => setModal3(!modal3)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Body>
            <h5 className="font_size_20 font_bold color_light_gray">
              Give atleast one answer
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                // type="button"
                onClick={() => setModal3(!modal3)}
                className="cmn--btn viewAllBtn rounded py-2"
              >
                OK
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          show={modal2}
          centered
          // onHide={() => setModal2(!modal2)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Body>
            <h5 className="font_size_20 font_bold color_light_gray">{msg}</h5>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                // type="button"
                onClick={() => {
                  if (isFailed) {
                    window.onbeforeunload = null
                    window.opener.location.reload()
                    window.close()
                  } else {
                    window.onbeforeunload = null
                    window.opener.location.reload()
                    window.close()
                  }
                }}
                className="cmn--btn viewAllBtn rounded py-2"
              >
                Ok
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Modal
          show={varificationModal}
          centered
          // onHide={() => setModal(!modal)}
          className="loginModal"
          // size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Body className="linear_gradient modalSelect rounded">
            <div className="">
              <div className="pb-3">
                <h3 className="text-center mb-3">Verification</h3>
                {/* <div className="textWhitefz26Bold">Verification</div> */}
                <h6>
                  Verification code is sent to your registered Mobile no and
                  Email address
                </h6>
              </div>
              <div className="mb-5 d-flex">
                <div className="col-md-12">
                  <h6
                    for="exampleFormControlInput1"
                    className="textWhitefz18Light font-weight-normal mb-2"
                  >
                    Enter Code
                  </h6>
                  <OtpInput
                    value={otp}
                    inputStyle={otpInput}
                    onChange={handleOtp}
                    numInputs={6}
                    separator={<span> </span>}
                    isInputNum={true}
                  />
                </div>
              </div>
              <div className="d-flex pe-2 justify-content-center">
                <div className="col-md-4 px-2">
                  <button
                    className="submit--btn w-100 form-control me-5"
                    onClick={otpVarification}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  )
}

export default TrainingExam
